"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isServer = function () { return !(typeof window !== 'undefined' && window.document); };
exports.isExperienceEditorActive = function () {
    if (exports.isServer()) {
        return false;
    }
    var sc = window.Sitecore;
    return Boolean(sc && sc.PageModes && sc.PageModes.ChromeManager);
};
exports.resetExperienceEditorChromes = function () {
    if (exports.isExperienceEditorActive()) {
        window.Sitecore.PageModes.ChromeManager.resetChromes();
    }
};
