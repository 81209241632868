"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var style_attr_1 = require("style-attr");
// https://stackoverflow.com/a/10426674/9324
exports.convertKebabCasetoCamelCase = function (str) {
    return str.replace(/^.|-./g, function (letter, index) { return (index === 0 ? letter.toLowerCase() : letter.substr(1).toUpperCase()); });
};
/**
 * https://facebook.github.io/react/docs/dom-elements.html
 * We are only concerned with style at the moment, which needs to be converted from string to object to satisfy React.
 * We don't need to convert any other attributes (that we know of), because the placeholder renders them "as-is" by using the special "is" React prop.
 * For whatever reason though, the "style" prop is still validated as needing to be an object when using the "is" prop, which is why we need to convert from string to object.
 */
exports.convertStyleAttribute = function (style) {
    if (style === void 0) { style = ''; }
    // styleParse converts a style attribute string into an object format
    var parsedStyle = style_attr_1.parse(style, { preserveNumbers: true });
    return Object.keys(parsedStyle).reduce(function (initialResult, styleKey) {
        var result = initialResult;
        var convertedKey = exports.convertKebabCasetoCamelCase(styleKey);
        result[convertedKey] = parsedStyle[styleKey];
        return result;
    }, {});
};
exports.convertAttributesToReactProps = function (attributes) {
    if (!attributes) {
        return [];
    }
    return Object.keys(attributes).reduce(function (initialResult, attrName) {
        var result = initialResult;
        switch (attrName) {
            case 'class': {
                result.className = attributes.class;
                break;
            }
            case 'style': {
                result.style = exports.convertStyleAttribute(attributes.style);
                break;
            }
            default: {
                result[attrName] = attributes[attrName];
                break;
            }
        }
        return result;
    }, {});
};
